import './ImageDropperCropper.css';
import Dropzone from 'react-dropzone';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
// import BlobUtil from 'blob-util';
import Jimp from 'jimp';
import 'react-image-crop/dist/ReactCrop.css';
import NeddieButton from '../../../components/neddieButton';
import { Dropdown, DropdownButton } from 'react-bootstrap';
// import addImageIcon from '../../../images/plus-solid.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { getOriginalFileURLFromCroppedURL } from '../../../utils/S3-ImageHandler';
import { IOnImageChangeEvent, IOnImageDeleteEvent } from '../../../types/shared.types';

interface Crop {
  unit: '%' | 'px' | undefined;
  width: number;
  height?: number;
  aspect: number;
}

const defaultCrop: Crop = {
  unit: '%',
  width: 100,
  aspect: 16 / 9
};

const squareCrop: Crop = {
  unit: '%',
  width: 100,
  aspect: 1 / 1
};

const spotlightCrop: Crop = {
  unit: '%',
  width: 100,
  aspect: 3 / 2
};

const spotlightLogoCrop: Crop = {
  unit: '%',
  width: 100,
  aspect: 9 / 9
};

interface IImageDropper {
  aspect: 'defaultCrop' | 'squareCrop' | 'spotlightCrop' | 'spotlightLogoCrop';
  defaultThumbNail?: string; //you can customize the drop zone when implementing the component. leave it empty string for default
  field: string;
  fileName: string;
  identifier?: string;
  onImageChange: (event: IOnImageChangeEvent) => void;
  onImageDelete: (event: IOnImageDeleteEvent) => void;
}
const ImageDropperCropper: React.FC<IImageDropper> = (props) => {
  // these are all of the hooks for this module.
  const [thumbNail, setThumbNail] = useState(props.defaultThumbNail || '');
  const [upImg, setUpImg] = useState('');
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [mode, setMode] = useState<'EDIT' | 'CHANGE' | 'DELETE'>('CHANGE');

  const [crop, setCrop] = useState(
    props.aspect === 'defaultCrop'
      ? defaultCrop
      : props.aspect === 'squareCrop'
      ? squareCrop
      : props.aspect === 'spotlightCrop'
      ? spotlightCrop
      : spotlightLogoCrop
  );

  const [completedCrop, setCompletedCrop] = useState(defaultCrop);
  const [cropStarted, setCropStarted] = useState(false);
  // max file size of 10mb
  const maxSize = 1048576 * 10;
  //************************ */

  const onSelectFile = (files: any) => {
    if (files && files.length > 0) {
      setCropStarted(true);
      const reader: any = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(files[0]);
      setMode('CHANGE');
    }
  };

  const onReject = (rejectedFiles: any) => {
    if (rejectedFiles.length > 1) {
      window.alert('Please select just one image file');
      return;
    }
    if (rejectedFiles[0].file.size > maxSize) {
      window.alert(`Your image file was too large`);
      return;
    }

    window.alert('Please select a valid image file');
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop: any = completedCrop;
    const dpr: any = window.devicePixelRatio || 1;

    const scaleX = image?.naturalWidth / image?.width;
    const scaleY = image?.naturalHeight / image?.height;
    const ctx = canvas?.getContext('2d');

    canvas.width = crop?.width * dpr;
    canvas.height = crop?.height * dpr;

    ctx.drawImage(
      image,
      crop?.x * scaleX,
      crop?.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * dpr,
      crop.height * dpr
    );
  }, [completedCrop]);

  // handle the blob creation

  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */

  async function getCroppedImg(image: any, crop: any, fileName: any) {
    const jimpImg = await Jimp.read(image.src).catch((err: any) =>
      console.log(err)
    );
    if (jimpImg) {
      const height = jimpImg.bitmap.height / 100;
      const width = jimpImg.bitmap.width / 100;
      jimpImg.crop(
        width * crop.x,
        height * crop.y,
        width * crop.width,
        height * crop.height
      );

      if (crop.aspect === 1 && jimpImg.bitmap.height > 400) {
        jimpImg.resize(Jimp.AUTO, 400);
      }
      if (jimpImg.bitmap.height > 1080) {
        jimpImg.resize(Jimp.AUTO, 1080);
      }
      jimpImg.quality(70);
      return new Blob([await jimpImg.getBufferAsync(Jimp.MIME_JPEG)], {
        type: 'image/jpeg'
      });
    }
  }

  async function getOriginalImg(image: any) {
    const jimpImg = await Jimp.read(image.src).catch((err: any) =>
      console.log(err)
    );
    if (jimpImg) {
      return new Blob([await jimpImg.getBufferAsync(Jimp.MIME_JPEG)], {
        type: 'image/jpeg'
      });
    }
  }

  async function handleCropSave() {
    if(mode === 'CHANGE') {
      const croppedImg = await getCroppedImg(
        imgRef.current,
        completedCrop,
        'image'
      );

      const originalImage = await getOriginalImg(imgRef.current);
     
      setThumbNail(URL.createObjectURL(croppedImg));

      props.onImageChange({
        croppedImage: croppedImg,
        croppedImageFileName: props.fileName,
        field: props.field,
        identifier: props.identifier ? props.identifier : 0,
        originalImage,
        originalImageFileName: `original_${props.fileName}`,
      });
     
      setCropStarted(false);
    }

    if(mode === 'EDIT') {
      const croppedImg = await getCroppedImg(
        imgRef.current,
        completedCrop,
        'image'
      );
     
      setThumbNail(URL.createObjectURL(croppedImg));

      props.onImageChange({
        croppedImage: croppedImg,
        croppedImageFileName: props.fileName,
        field: props.field,
        identifier: props.identifier ? props.identifier : 0,
      });
     
      setCropStarted(false);
    }
  }

  async function handleEditImage(event: any) {
    event.preventDefault();

    if (!props.defaultThumbNail) {
      return;
    }

    const jimpImg = await Jimp.read(
      getOriginalFileURLFromCroppedURL(props.defaultThumbNail)
    ).catch((err: any) => console.log(err));

    if (jimpImg) {
      const base64Img = await jimpImg.getBase64Async(Jimp.MIME_JPEG);
      setUpImg(base64Img);
      setCropStarted(true);
      setMode('EDIT');
    } else {
        const croppedjimpImg = await Jimp.read(props.defaultThumbNail).catch((err: any) => console.log(err));
    
        if (croppedjimpImg) {
          const base64Img = await croppedjimpImg.getBase64Async(Jimp.MIME_JPEG);
          setUpImg(base64Img);
          setCropStarted(true);
          setMode('EDIT');
        }
    }
  }

  function handleChangeImage(event: any, open: () => void) {
    event.preventDefault();
    open();
    setMode('CHANGE');
  }

  function handleDeleteImage(event: any) {
    event.preventDefault();
    setMode('DELETE');
    setThumbNail('');

    props.onImageDelete({
      field: props.field,
      identifier: props.identifier ? props.identifier : 0,
      url: props.defaultThumbNail || '',
    });
   
    setCropStarted(false);
  }

  return (
    <div className='npp-image-dropper-cropper'>
      <Dropzone
        accept='image/*'
        maxSize={maxSize}
        multiple={false}
        onDropAccepted={(acceptedFiles) => onSelectFile(acceptedFiles)}
        onDropRejected={(rejectedFiles) => onReject(rejectedFiles)}
      >
        {({ getRootProps, getInputProps, open }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              {thumbNail === '' ? (
                <div className="npp-drop-zone-container">
                  <div className="plus-sign">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      className="svg-inline--fa fa-plus fa-w-14 fa-2x"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#aaa0a0"
                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                      ></path>
                    </svg>
                  </div>
                </div>
              ) : (
                <div
                  className="npp-drop-zone-container"
                  style={{ backgroundImage: `url(${thumbNail})` }}
                >
                  {!!props.defaultThumbNail && (
                    <DropdownButton
                      id="dropdown-basic-button"
                      onClick={(e) => e.stopPropagation()}
                      className="npp-dropper-cropper-edit-dropdown"
                      title={<FontAwesomeIcon icon={faPen} />}
                    >
                      <Dropdown.Item as="button" onClick={handleEditImage}>
                        Edit Image
                      </Dropdown.Item>

                      <Dropdown.Item
                        as="button"
                        onClick={(e) => handleChangeImage(e, open)}
                      >
                        Change Image
                      </Dropdown.Item>
                      
                      <Dropdown.Item as="button" onClick={handleDeleteImage}>
                        Delete Image
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
      <div
        style={{ display: cropStarted ? 'flex' : 'none' }}
        className='npp-modal-cropper-wrapper'
      >
        <div className='npp-modal-cropper-title'>
          Drag to Reposition the Crop
        </div>
        <ReactCrop
          className='npp-modal-cropper'
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(pix: any, c: any) => setCrop(c)}
          onComplete={(pix: any, c: any) => setCompletedCrop(c)}
        />
        <div className='npp-dropper-cropper-buttons'>
          <div className='save-button'>
            <NeddieButton
              onClick={(e: any) => {
                e.preventDefault();
                handleCropSave();
              }}
              value='Save'
              isSquare={true}
              type='button'
            ></NeddieButton>
          </div>
          <div>
            {' '}
            <NeddieButton
              onClick={() => setCropStarted(false)}
              value='Cancel'
              isSquare={true}
              isLight={true}
              type='button'
            ></NeddieButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDropperCropper;
