import './sharingOptions.css';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share';
import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import NeddieButton from '../../components/neddieButton';
import share from './images/share.svg';
import twitterX from '../../images/HomeNewImage/twitter-x-logo.png';

interface ISharingOption {
  componentHeaderText: string;
  url: string;
  postDescription: string;
  twitterHashtags: string[];
  shareSymbolLight?: boolean;
  shareButton?: boolean;
  shareButtonLight?: boolean;
}

const SharingOptions: React.FC<ISharingOption> = (props) => {
  const [openModal, setOpenModal] = useState(false);

  console.log(props.shareButtonLight);
  console.log(props.shareButton);

  return (
    <>
      {props.shareButton === true ? (
        //share button
        <NeddieButton
          type='share'
          value={'Share'}
          onClick={() => setOpenModal(!openModal)}
          isLight={props.shareButtonLight ? true : false}
          isSquare={true}
        ></NeddieButton>
      ) : (
        //share symbol
        <img
          className={
            props.shareSymbolLight
              ? 'share-symbol-img share-symbol-white'
              : 'share-symbol-img share-symbol-blue'
          }
          alt='share symbol'
          src={share}
          onClick={() => setOpenModal(true)}
        ></img>
      )}

      <Modal centered show={openModal} onHide={() => setOpenModal(false)}>
        <button className='close-modal-x' onClick={() => setOpenModal(false)}>X</button>
        <div className='sharing-options-modal-body'>
          <h4 className='sharing-options-modal-title'>{props.componentHeaderText}</h4>
          <div className='sharing-options-options-container'>
            <div className='sharing-options-options-container-child'>
              <div className='options-btn-container'>
                <FacebookShareButton className='sharing-Options-btn' url={props.url} quote={props.postDescription} hashtag='NEDDIE'>
                  <FacebookIcon size={32} round={true} />
                  <small className='text-muted ml-1'>Facebook</small>
                </FacebookShareButton>
              </div>
              <div className='options-btn-container'>
                <TwitterShareButton className='sharing-Options-btn' url={props.url} title={props.postDescription} hashtags={props.twitterHashtags}>
                  <img src={twitterX} alt="" width={'31px'} height={'31px'} />
                  <small className='text-muted ml-1'>X (Twitter)</small>
                </TwitterShareButton>
              </div>
            </div>

            <div className='sharing-options-options-container-child'>
              <div className='options-btn-container'>
                <LinkedinShareButton className='sharing-Options-btn' url={props.url} summary={props.postDescription}>
                  <LinkedinIcon size={32} round={true} />
                  <small className='text-muted ml-1'>LinkedIn</small>
                </LinkedinShareButton>
              </div>
              <div className='options-btn-container'>
                <EmailShareButton className='sharing-Options-btn' url={props.url} subject={props.postDescription}>
                  <EmailIcon size={32} round={true} />
                  <small className='text-muted ml-1'>Email</small>
                </EmailShareButton>
              </div>
            </div>
          </div>
          <div className='close-button-container'>
            <NeddieButton value='Cancel' type='button' onClick={() => setOpenModal(false)} isSquare={true} isLight={false}></NeddieButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SharingOptions;
